export default {
  PUSH_AUDIT(state, payload) {
    state.audit_list = payload
  },
  PUSH_NOTIFICATIONS(state, payload) {
    state.notifications = payload.notifications
    state.unreadNotifications = payload.unreadNotifications
    state.notificationLabel = payload.notificationLabel
  },
  CLEAR_NOTIFICATION_LABEL(state, payload) {
    state.notificationLabel = payload
  },
  RESET_AUDIT(state) {
    state.audit = []
    state.notifications = []
    state.notificationLabel = false
  },
  PUSH_NOTIFICATION_SOCKET(state, payload) {
    state.notifications.push(payload)
    state.unreadNotifications.push(payload)
    state.notificationLabel = true
  },

}
