export default {
  PUSH_SCHEDULES(state, payload) {
    state.schedule_list = payload
  },
  RESET_SCHEDULES(state, payload) {
    state.schedules = []
  },
  ADD_SCHEDULES(state, payload) {
    state.schedules.push(payload)
    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  DEL_SCHEDULES(state, payload) {
    const index = state.schedules.findIndex(x => x._id === payload.id)
    state.schedules.splice(index, 1)
    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  PUT_SCHEDULES(state, payload) {
    const index = state.schedules.findIndex(x => x.id === payload.oldEvent.id)
    state.schedules.splice(index, 1)
    state.schedules.push(payload.eventData)
    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  PUT_REVISION(state, payload) {
    const index = state.schedules.findIndex(x => x._id === payload.id)
    const oldEvent = state.schedules[index]
    oldEvent.totalAmount = payload.totalAmount
    oldEvent.reviewed = payload.reviewed
    oldEvent.invoiceNumber = payload.invoiceNumber
    state.schedules.splice(index, 1)
    state.schedules.push(oldEvent)
  },
  PUT_DRAG_SCHEDULES: (state, payload) => {
    const index = state.schedules.findIndex(x => x.id === payload.eventData.id)
    const oldSchedule = state.schedules[index]

    oldSchedule.start = payload.eventData.start
    oldSchedule.end = payload.eventData.end
    oldSchedule.resourceId = payload.eventData.resource
    oldSchedule.totalAmount = payload.eventData.totalAmount || oldSchedule.totalAmount
    oldSchedule.reviewed = payload.eventData.reviewed || oldSchedule.reviewed

    if (payload.eventData.allDay === false || payload.eventData.allDay === true) {
      oldSchedule.allDay = payload.eventData.allDay
    }
    state.schedules.splice(index, 1)
    state.schedules.push(oldSchedule)
    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  PUT_BGCOLOR_SCHEDULES: (state, payload) => {
    state.schedules.forEach(element => {
      if (payload.dataType === 'employee') {
        if (element.resource === payload.id) {
          const index = state.schedules.findIndex(x => x.id == element.id)
          const newSchedData = state.schedules[index]

          newSchedData.backgroundColor = payload.color
          newSchedData.borderColor = payload.color

          state.schedules.splice(index, 1)
          state.schedules.push(newSchedData)
        }
      } else if (payload.dataType === 'state') {
        if (element.state === payload.id) {
          const index = state.schedules.findIndex(x => x.id == element.id)
          const newSchedData = state.schedules[index]

          newSchedData.borderColor = payload.color

          state.schedules.splice(index, 1)
          state.schedules.push(newSchedData)
        }
      }
    })

    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  SET_LOADING_EVENTS(state) {
    state.loadingEvents = !state.loadingEvents
  },
  ADD_SCHEDULE_SOCKET(state, payload) {
    const index = state.schedules.findIndex(x => x._id === payload._id)
    if (index === -1) {
      state.schedules.push(payload)
    }
    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  PUT_SCHEDULE_SOCKET(state, payload) {
    const index = state.schedules.findIndex(x => x.id === payload.id)
    if (index > -1) {
      state.schedules.splice(index, 1)
      state.schedules.push(payload)
    }

    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  PUT_SCHEDULE_DRAG_SOCKET(state, payload) {
    const index = state.schedules.findIndex(x => x.id === payload.id)
    if (index > -1) {
      const oldEvent = state.schedules[index]
      oldEvent.end = payload.end
      oldEvent.start = payload.start
      oldEvent.resource = payload.resource
      oldEvent.allDay = payload.allDay ? payload.allDay : false
      state.schedules.splice(index, 1)
      state.schedules.push(oldEvent)
    }
    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
  DEL_SCHEDULE_SOCKET(state, payload) {
    const index = state.schedules.findIndex(x => x._id === payload.id)
    if (index > -1) {
      state.schedules.splice(index, 1)
    }
    // LocalForageFunctions.setItem('schedules', state.schedules)
  },
}
