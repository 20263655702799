export default {
  pushServices({ commit }, payload) {
    commit('PUSH_SERVICES', payload)
  },
  resetServices({ commit }) {
    commit('RESET_SERVICES')
  },
  addServices({ commit }, payload) {
    const service = {
      serviceName: payload.serviceData.serviceName,
      serviceCategory: payload.serviceData.serviceCategory,
      servicePrice: payload.serviceData.servicePrice,
      serviceDuration: payload.serviceData.serviceDuration,
      serviceEnabled: payload.serviceData.serviceEnabled,
      serviceIcon: payload.serviceData.serviceIcon,
    }
    Axios.post('/addService', { service })
      .then(function (response) {
        if (response.data.success) {
          const newService = {
            id: response.data.serviceData._id,
            serviceName: payload.serviceData.serviceName,
            serviceCategory: payload.serviceData.serviceCategory,
            servicePrice: payload.serviceData.servicePrice,
            serviceDuration: payload.serviceData.serviceDuration,
            serviceEnabled: payload.serviceData.serviceEnabled,
          }
          commit('ADD_SERVICES', newService)
          payload.notify({
            title: 'Account Created',
            text: 'Customer created in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delServices({ commit }, payload) {
    Axios.post('/delService', { service: payload.serviceData })
      .then(function (response) {
        if (response.data.success) {
          commit('DEL_SERVICES', payload.serviceData)
          payload.notify({
            title: 'Service Deleted',
            text: 'Service deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  putServices: async (context, payload) => {
    const service = {
      id: payload.newData.id,
      serviceName: payload.newData.serviceName,
      serviceCategory: payload.newData.serviceCategory,
      servicePrice: payload.newData.servicePrice,
      serviceDuration: payload.newData.serviceDuration,
      serviceEnabled: payload.newData.serviceEnabled,
    }
    Axios.post('/putService', { service })
      .then(function (response) {
        if (response.data.success) {
          context.commit('PUT_SERVICES', payload)
          payload.notify({
            title: 'Account updated',
            text: 'Service updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delSelectedServices(conext, payload) {
    Axios.post('/delMultiService', { service: payload.serviceData })
      .then(function (response) {
        if (response.data.success) {
          conext.commit('DEL_SELECTED_SERVICES', payload)
          payload.notify({
            title: 'Services Deleted',
            text: 'Multi Services deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {
      })
  },
}
