export default {
  PUSH_SERVICES(state, payload) {
    state.service_list = payload
  },
  RESET_SERVICES(state) {
    state.services = []
    // LocalForageFunctions.setItem('services', state.services)
  },
  ADD_SERVICES(state, payload) {
    state.services.push(payload)
    // LocalForageFunctions.setItem('services', state.services)
  },
  DEL_SERVICES(state, payload) {
    const index = state.services.findIndex(x => x.id === payload.id)
    state.services.splice(index, 1)
    // LocalForageFunctions.setItem('services', state.services)
  },
  PUT_SERVICES(state, payload) {
    const index = state.services.findIndex(x => x.id === payload.serviceData.id)
    state.services.splice(index, 1)
    state.services.push(payload.newData)
    // LocalForageFunctions.setItem('services', state.services)
  },
  DEL_SELECTED_SERVICES(state, payload) {
    payload.serviceData.forEach(element => {
      const index = state.services.findIndex(x => x.id === element.id)
      state.services.splice(index, 1)
    })
    // LocalForageFunctions.setItem('services', state.services)
  },
}
