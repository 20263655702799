import { auth } from '@/auth/firebase'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'firebase/firestore'

import router from '@/router'
import Axios from 'axios'

export default {
  async login({
    commit, state, rootState, dispatch,
  }, payload) {
    // If user is already logged in notify and exit
    try {
      if (state.isUserLoggedIn()) {
        this._vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Login',
            icon: 'KeyIcon',
            text: 'La sesión ya está iniciada.',
            variant: 'warning',
          },
        })
        return false
      }
      // Try to sigin
      signInWithEmailAndPassword(auth, payload.userDetails.email, payload.userDetails.password)
        .then(async result => {
          this._vm.$http.defaults.headers.common['x-access-token'] = await result.user.getIdToken()
          await dispatch('generalBusiness/getAllBusinessData', {}, { root: true })
          //router.push('/')
          this._vm.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Hola!',
              icon: 'KeyIcon',
              text: `👋  Nos alegra verte de nuevo, ${result.user.displayName}`,
              variant: 'success',
            },
          })
          return true
        }, err => {
          commit('UPDATE_USER_API_STATUS', false)
          this._vm.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Uops!',
              icon: 'KeyIcon',
              text: `Parece que ha ocurrido un error: ${err.message}`,
              variant: 'danger',
            },
          })
          return false
        })
    } catch (err) {
      this._vm.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Uops!',
          icon: 'KeyIcon',
          text: `Parece que ha ocurrido un error: ${err.message}`,
          variant: 'danger',
        },
      })
      return false
    }
  },
  resetPassword({ dispatch }, payload) {
    // New payload for login action
    dispatch('businessData/changeFirstLoginStatus', payload, { root: true })
    /* var user = firebase.auth().currentUser;

        user.updatePassword(payload.password).then(function() {
            payload.notify({
                time: 2500,
                //title: '',
                text: 'Contraseña cambiada',
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success'
            });

            router.replace("/");
            payload.loading.close()
        }).catch(function(error) {
            if(error.code === 'auth/requires-recent-login'){

            }

            payload.notify({
                time: 2500,
                title: 'Error',
                text: 'Cierre sesión y vuelva a intentarlo.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
            payload.loading.close()
        }); */
  },
  // Google Login
  loginWithGoogle({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new auth.GoogleAuthProvider()

    auth.signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_AUTHENTICATED_USER', result.user.providerData[0])
      }).catch(err => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    // firebase.auth().onAuthStateChanged(function(user) {
    //     if (user) {
    //         user.updateProfile({
    //             roles: ['admin'],
    //         }).then(function() {
    //             this.$vs.notify({
    //                 title: 'Success',
    //                 text: "done",
    //                 iconPack: 'feather',
    //                 icon: 'icon-check',
    //                 color: 'success'
    //             });
    //         }, function(error) {
    //             this.$vs.notify({
    //                 title: 'Error',
    //                 text: error.message,
    //                 iconPack: 'feather',
    //                 icon: 'icon-alert-circle',
    //                 color: 'danger'
    //             });
    //         });

    //     }
    // });
  },

  // Facebook Login
  loginWithFacebook({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new auth.FacebookAuthProvider()

    auth.signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_AUTHENTICATED_USER', result.user.providerData[0])
      }).catch(err => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Twitter Login
  loginWithTwitter({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new auth.TwitterAuthProvider()

    auth.signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_AUTHENTICATED_USER', result.user.providerData[0])
      }).catch(err => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new auth.GithubAuthProvider()

    auth.signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_AUTHENTICATED_USER', result.user.providerData[0])
      }).catch(err => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
  registerUser({ dispatch, commit }, payload) {
    payload.loading()
    Axios.post('/check-email', { email: payload.userDetails.email })
      .then(response => {
        if (response.data.success) {
          auth.createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
            .then(registeredUser => {
              Axios.defaults.headers.common['x-access-token'] = registeredUser.user.ra

              Axios.post('/register',
                {
                  uid: registeredUser.user.uid,
                  email: payload.userDetails.email,
                  username: payload.userDetails.username,
                  businessName: payload.userDetails.businessName,
                })
                .then(response => {
                  if (response.data.success) {
                    const newPayload = {
                      userDetails: payload.userDetails,
                      notify: payload.notify,
                      updateUsername: true,
                      loading: payload.loading,
                    }
                    dispatch('login', newPayload)
                  } else {
                    payload.loading.close()
                    commit('UPDATE_USER_API_STATUS', false)
                    payload.notify({
                      title: 'Error',
                      text: response.data.message,
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger',
                    })
                  }
                })
                .catch(error => {
                  payload.loading.close()
                  commit('UPDATE_USER_API_STATUS', false)
                })
            }, error => {
              payload.loading.close()
              commit('UPDATE_USER_API_STATUS', false)
              payload.notify({
                title: 'Error',
                text: error.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
              })
            })
        } else {
          payload.loading.close()
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {
        payload.loading.close()
        payload.notify({
          title: 'Error',
          text: error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        // commit('UPDATE_USER_API_STATUS', false)
      })
    // create user using firebase
  },
  updateUsername({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.username,
    }).then(() => {
      // If username update is success
      // update in localstorage
      const newUserData = { ...payload.user.providerData[0] }
      newUserData.displayName = payload.username
      localStorage.setItem('userInfo', JSON.stringify(newUserData))
      commit('UPDATE_AUTHENTICATED_USER', newUserData)

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch(err => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    })
  },
  async logOut({ commit, dispatch }, payload) {
    const firebaseCurrentUser = auth.currentUser
    if (firebaseCurrentUser) {
      await signOut(auth).then(() => {
        localStorage.removeItem('userInfo')
        dispatch('generalBusiness/resetBusinessData', {}, { root: true })
        //router.push('/')
      }).catch((error) => {
        console.log("Error ", err)
        // An error happened.
      })
      //router.push('/')
    }
  },
  updateAuthenticatedUser({ commit }, payload) {
    commit('UPDATE_AUTHENTICATED_USER', payload)
  },
}
