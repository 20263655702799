export default {
  UPDATE_AUTHENTICATED_USER(state, user) {
    state.userInfo = user
    localStorage.setItem('userInfo', JSON.stringify(user))
    localStorage.setItem('userRole', 'admin')
  },
  UPDATE_USER_API_STATUS(state, status) {
    state.isUserApiChek = status
  },
}
