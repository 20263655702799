import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleAuth from './auth/moduleAuth'
import moduleGeneralBusiness from './generalBusiness/moduleAllData'
import moduleResources from './resources/moduleResources'
import moduleSchedule from './schedule/moduleSchedule'
import moduleCustomers from './customers/moduleCustomers'
import moduleServices from './services/moduleServices'
import moduleActivities from './activities/moduleActivities'
import moduleBonus from './bonus/moduleBonus'
import moduleStates from './states/moduleStates'
import moduleAudit from './audit/moduleAudit'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: moduleAuth,
    generalBusiness: moduleGeneralBusiness,
    resources: moduleResources,
    schedules: moduleSchedule,
    customers: moduleCustomers,
    services: moduleServices,
    activities: moduleActivities,
    bonus: moduleBonus,
    states: moduleStates,
    audit: moduleAudit,
  },
  strict: process.env.DEV,
})
