export default {
  pushActivities({ commit }, payload) {
    commit('PUSH_ACTIVITIES', payload)
  },
  resetActivities({ commit }) {
    commit('RESET_ACTIVITIES')
  },
  addActivities({ commit }, payload) {
    const activity = {
      activityName: payload.activityData.serviceName,
      activityCategory: payload.activityData.serviceCategory,
      activityPrice: payload.activityData.servicePrice,
      activityDuration: payload.activityData.serviceDuration,
      activityEnabled: payload.activityData.serviceEnabled,
      activityLimit: payload.activityData.serviceLimit,
    }
    Axios.post('/addActivity', { activity })
      .then(function (response) {
        if (response.data.success) {
          const newActivity = {
            id: response.data.activityData._id,
            activityName: payload.activityData.serviceName,
            activityategory: payload.activityData.serviceCategory,
            activityPrice: payload.activityData.servicePrice,
            activityDuration: payload.activityData.serviceDuration,
            activityEnabled: payload.activityData.serviceEnabled,
            activityLimit: payload.activityData.serviceLimit,
          }
          commit('ADD_ACTIVITIES', newActivity)
          payload.notify({
            title: 'Activity Created',
            text: 'Activity created in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delActivities({ commit }, payload) {
    Axios.post('/delActivity', { activity: payload.activityData })
      .then(function (response) {
        if (response.data.success) {
          commit('DEL_ACTIVITIES', payload.activityData)
          payload.notify({
            title: 'Activity Deleted',
            text: 'Activity deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  putActivities: async (context, payload) => {
    const activity = {
      id: payload.newData.id,
      activityName: payload.newData.serviceName,
      activityCategory: payload.newData.serviceCategory,
      activityPrice: payload.newData.servicePrice,
      activityDuration: payload.newData.serviceDuration,
      activityEnabled: payload.newData.serviceEnabled,
      activityLimit: payload.newData.serviceLimit,
    }
    Axios.post('/putActivity', { activity })
      .then(function (response) {
        if (response.data.success) {
          context.commit('PUT_ACTIVITIES', activity)
          payload.notify({
            title: 'Activity updated',
            text: 'Activity updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delSelectedActivities(conext, payload) {
    Axios.post('/delMultiActivity', { activity: payload.activityData })
      .then(function (response) {
        if (response.data.success) {
          conext.commit('DEL_SELECTED_ACTIVITIES', payload)
          payload.notify({
            title: 'Activities Deleted',
            text: 'Multi Activities deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {
      })
  },
}
