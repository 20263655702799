import axios from '@axios'

export default {
  pushCustomers({ commit }, payload) {
    commit('PUSH_CUSTOMERS', payload)
  },
  resetCustomers({ commit }) {
    commit('RESET_CUSTOMERS')
  },
  setCustomerSearchQuery({ commit }, query) {
    commit('SET_CUSTOMER_SEARCH_QUERY', query)
  },
  sellBonus({ commit }, payload) {
    commit('ADD_CUSTOMER_BONUS', payload)
  },
  updateCustomerBonus({ commit }, payload) {
    commit('PUT_CUSTOMER_BONUS', payload)
  },
  async addCustomers({ commit, dispatch }, payload) {
    try {
      const newCustomer = await axios.post('/createCustomer', payload)
      if (newCustomer.data.success) commit('ADD_CUSTOMERS', newCustomer.data.data)
      else console.log('Error')
    } catch (e) {
      console.log('e ', e)
    }
  },
  async updateCustomers({ commit, dispatch }, payload) {
    try {
      const newCustomer = await axios.post('/updateCustomer', payload)
      if (newCustomer.data.success) commit('PUT_CUSTOMERS', payload)
      else console.log('Error')
    } catch (e) {
      console.log('e ', e)
    }
  },
  async deleteCustomers({ commit }, payload) {
    try {
      const newCustomer = await axios.post('/deleteCustomer', payload)
      if (newCustomer.data.success) {
        commit('DEL_CUSTOMERS', payload.customerId)
        return true
      }
      console.log('Error')
      return false
    } catch (e) {
      console.log('e ', e)
      return false
    }
  },
  fupdateCustomers: async (context, payload) => {
    const customer = {
      id: payload.newData.id,
      customerName: payload.newData.name,
      customerMail: payload.newData.email,
      customerPhone: payload.newData.phone,
    }
    return Axios.post('/putCustomer', { customer })
      .then(function (response) {
        if (response.data.success) {
          payload.contactData = { ...customer }
          context.commit('PUT_CUSTOMERS', payload)
          payload.notify({
            title: 'Account updated',
            text: 'Customer updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
          return payload.newData
        } if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  uploadCustomers({ commit, dispatch }, payload) {
    Axios.post('/bulkCustomers', { customers: payload.customerlist })
      .then(function (response) {
        if (response.data.success) {
          const bulkCustomers = []
          for (let i = 0; i < response.data.customerData.length; i++) {
            bulkCustomers.push({
              id: response.data.customerData[i]._id,
              name: response.data.customerData[i].customerName,
              email: response.data.customerData[i].customerMail,
              phone: response.data.customerData[i].customerPhone,
            })
          }
          commit('BULK_CUSTOMERS', bulkCustomers)
          payload.notify({
            title: 'Account Created',
            text: 'Customer created in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delCustomers({ commit }, payload) {
    Axios.post('/delCustomer', { customer: payload.customerData })
      .then(function (response) {
        if (response.data.success) {
          commit('DEL_CUSTOMERS', payload.customerData)
          payload.notify({
            title: 'Customer Deleted',
            text: 'Customer deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  putCustomers: async (context, payload) => {
    const customer = {
      id: payload.newData.id,
      customerName: payload.newData.name,
      customerMail: payload.newData.email,
      customerPhone: payload.newData.phone,
    }
    return Axios.post('/putCustomer', { customer })
      .then(function (response) {
        if (response.data.success) {
          payload.contactData = { ...customer }
          context.commit('PUT_CUSTOMERS', payload)
          payload.notify({
            title: 'Account updated',
            text: 'Customer updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
          return payload.newData
        } if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delSelectedCustomers(conext, payload) {
    Axios.post('/delMultiCustomer', { customer: payload.customerData })
      .then(function (response) {
        if (response.data.success) {
          conext.commit('DEL_SELECTED_CUSTOMERS', payload)
          payload.notify({
            title: 'Customers Deleted',
            text: 'Multi Customers deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {
      })
  },
}
