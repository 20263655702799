import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const config = {
  apiKey: 'AIzaSyBNIBnTbCdt-nBPKCIZaCMj6ovwqC-pdXY',
  authDomain: 'vuefirestore-40f0a.firebaseapp.com',
  databaseURL: 'https://vuefirestore-40f0a.firebaseio.com',
  projectId: 'vuefirestore-40f0a',
  storageBucket: 'vuefirestore-40f0a.appspot.com',
  messagingSenderId: '701308496141',
  appId: '1:701308496141:web:2a3db969c6f7f654',
}

// Initialize Firebase
const app = initializeApp(config)

export const auth = getAuth(app)

export default { auth }
