import { auth } from '@/auth/firebase'

export default {
  isUserLoggedIn: () => {
    let isAuthenticated = false

    // get firebase current user
    const firebaseCurrentUser = auth.currentUser

    if (firebaseCurrentUser) {
      isAuthenticated = true
    } else {
      isAuthenticated = false
    }

    return (localStorage.getItem('userInfo') && isAuthenticated)
  },
  userInfo: {},
  isUserApiCheck: false,
}
