export default {
  PUSH_BONUS(state, payload) {
    state.bonus_list = payload
  },
  RESET_BONUS(state) {
    state.bonus = []
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  ADD_BONUS(state, payload) {
    state.bonus.push(payload)
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  DEL_BONUS(state, payload) {
    const index = state.bonus.findIndex(x => x.id === payload.id)
    state.bonus.splice(index, 1)
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  PUT_BONUS(state, payload) {
    const index = state.bonus.findIndex(x => x.id === payload.id)
    state.bonus.splice(index, 1)
    state.bonus.push(payload)
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  DEL_SELECTED_ACTIVITIES(state, payload) {
    payload.activityData.forEach(element => {
      const index = state.activities.findIndex(x => x.id == element.id)
      state.activities.splice(index, 1)
    })
    // LocalForageFunctions.setItem('activities', state.activities)
  },
}
