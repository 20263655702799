import state from './moduleStatesState'
import mutations from './moduleStatesMutations'
import actions from './moduleStatesActions'
import getters from './moduleStatesGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
