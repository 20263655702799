export default {
  PUSH_ACTIVITIES(state, payload) {
    state.activity_list = payload
  },
  RESET_ACTIVITIES(state) {
    state.activities = []
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  ADD_ACTIVITIES(state, payload) {
    state.activities.push(payload)
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  DEL_ACTIVITIES(state, payload) {
    const index = state.activities.findIndex(x => x.id === payload.id)
    state.activities.splice(index, 1)
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  PUT_ACTIVITIES(state, payload) {
    const index = state.activities.findIndex(x => x.id === payload.id)
    state.activities.splice(index, 1)
    state.activities.push(payload)
    // LocalForageFunctions.setItem('activities', state.activities)
  },
  DEL_SELECTED_ACTIVITIES(state, payload) {
    payload.activityData.forEach(element => {
      const index = state.activities.findIndex(x => x.id == element.id)
      state.activities.splice(index, 1)
    })
    // LocalForageFunctions.setItem('activities', state.activities)
  },
}
