import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axiosIns from '@axios'

import i18n from '@/libs/i18n'
import { auth } from './auth/firebase'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

let app = null

auth.onAuthStateChanged(async user => {
  if (user) {
    const idToken = await user.getIdToken()
    axiosIns.defaults.headers.common['x-access-token'] = idToken
    axiosIns.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    await store.dispatch('generalBusiness/getAllBusinessData', {}, { root: true })
    if (!app) {
      app = new Vue({
        router,
        store,
        i18n,
        render: h => h(App),
      }).$mount('#app')
    }
  }
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }
})

/* new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app') */
