export default {
  pushAudit({ commit }, payload) {
    commit('PUSH_AUDIT', payload)
  },
  pushUnreadNotifications({
    commit, getters, rootState, rootGetters,
  }, payload) {
    const notifications = []
    let notificationLabel = ''
    const uid = rootGetters['businessData/UID']
    const ownUid = rootGetters['businessData/OWNUID']
    const employees = rootGetters['employees/EMPLOYEES']
    const currentUser = employees.filter(x => x.id === ownUid)
    let unreadFlag = false
    const unreadNotifications = currentUser[0].unreadNotifications.filter(x => x.read === false)
    const allNotifications = deepClone(currentUser[0].unreadNotifications)

    for (let i = 0; i < unreadNotifications.length; i++) {
      const notification = unreadNotifications[i]
      switch (notification.category) {
        case 'customer':
          if (currentUser[0].notifications.showCustomersN && notification.resourceId != uid && uid === ownUid) {
            unreadFlag = true
            notifications.push(notification)
          }
          break
        case 'resource':
          if (currentUser[0].notifications.showResourcesN && notification.resourceId != uid && uid === ownUid) {
            unreadFlag = true
            notifications.push(notification)
          }
          break
        case 'service':
          if (currentUser[0].notifications.showServicesN && notification.resourceId != uid && uid === ownUid) {
            unreadFlag = true
            notifications.push(notification)
          }
          break
        case 'state':
          if (currentUser[0].notifications.showStatesN && notification.resourceId != uid && uid === ownUid) {
            unreadFlag = true
            notifications.push(notification)
          }
          break
        case 'schedule':
          if (currentUser[0].notifications.showSchedulesN && notification.resourceId != ownUid && notification.assignedTo === ownUid) {
            unreadFlag = true
            notifications.push(notification)
          }
          break
      }

      if (unreadFlag) {
        notificationLabel = true
      } else {
        notificationLabel = false
      }
    }
    commit('PUSH_NOTIFICATIONS', { notificationLabel, unreadNotifications: notifications.sort((a, b) => ((a.changeData.changeDate > b.changeData.changeDate) ? -1 : ((b.changeData.changeDate > a.changeData.changeDate) ? 1 : 0))), notifications: allNotifications.sort((a, b) => ((a.changeData.changeDate > b.changeData.changeDate) ? -1 : ((b.changeData.changeDate > a.changeData.changeDate) ? 1 : 0))) })
  },
  putUnreadStatus({ commit }, payload) {
    Axios.post('/clearAuditNotification')
      .then(response => {
        commit('employees/PUT_RESOURCE_NOTIFICATIONS', { userId: payload }, { root: true })
      })
      .catch(error => {
      })
  },
  clearUnreadNotificationLabel({ commit, dispatch }, payload) {
    commit('CLEAR_NOTIFICATION_LABEL', payload.status)
    dispatch('employees/putUnreadNotifications', payload.ownUid, { root: true })
  },
  changeNotificationLabelStatus({ commit }, payload) {
    commit('CLEAR_NOTIFICATION_LABEL', payload)
  },
  resetAudit: async ({ commit }) => {
    commit('RESET_AUDIT')
  },
}
