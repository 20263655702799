export default {
  pushStates({ commit }, payload) {
    commit('PUSH_STATES', payload)
  },
  resetStates({ commit }) {
    commit('RESET_STATES')
  },
  addStates({ commit, dispatch }, payload) {
    const state = {
      stateName: payload.stateData.serviceName,
      stateColor: payload.stateData.color,
      stateIcon: payload.stateData.icon,
    }
    Axios.post('/addState', { state })
      .then(function (response) {
        if (response.data.success) {
          const newState = {
            id: response.data.stateData._id,
            serviceName: response.data.stateData.stateName,
            color: response.data.stateData.stateColor,
            icon: response.data.stateData.stateIcon,
          }
          commit('ADD_STATES', newState)
          dispatch('colorStateExceptions/addStateColorExceptions', state.stateColor, { root: true })
          payload.notify({
            title: 'State Created',
            text: 'State created in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delStates({ commit, dispatch }, payload) {
    Axios.post('/delState', { state: payload.stateData })
      .then(function (response) {
        if (response.data.success) {
          commit('DEL_STATES', payload.stateData)
          dispatch('colorStateExceptions/deleteStateColorExceptions', payload.stateData.color, { root: true })
          payload.notify({
            title: 'State Deleted',
            text: 'State deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  putStates: async ({ commit, dispatch }, payload) => {
    const state = {
      id: payload.newData.id,
      stateName: payload.newData.serviceName,
      color: payload.newData.color,
      icon: payload.newData.icon,
    }
    Axios.post('/putState', { state, oldColor: payload.stateData.color })
      .then(function (response) {
        if (response.data.success) {
          commit('PUT_STATES', payload)
          dispatch('colorStateExceptions/updateStateColorExceptions', {
            newColor: payload.newData.color,
            oldColor: payload.stateData.color,
          }, { root: true })

          payload.newData.dataType = 'state'
          dispatch('schedules/updateBgColor', payload.newData, { root: true })
          payload.notify({
            title: 'State updated',
            text: 'State updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delSelectedStates({ commit, dispatch }, payload) {
    Axios.post('/delMultiState', { state: payload.stateData })
      .then(function (response) {
        if (response.data.success) {
          commit('DEL_SELECTED_STATES', payload)
          dispatch('colorStateExceptions/deleteSelectedStateColorExceptions', payload.stateData, { root: true })
          payload.notify({
            title: 'States Deleted',
            text: 'Multi States deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {
      })
  },
}
