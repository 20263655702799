import state from './moduleAuditState'
import mutations from './moduleAuditMutations'
import actions from './moduleAuditActions'
import getters from './moduleAuditGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
