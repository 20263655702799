/*= ========================================================================================
  File Name: moduleCustomers.js
  Description: Customers Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import state from './moduleServicesState'
import mutations from './moduleServicesMutations'
import actions from './moduleServicesActions'
import getters from './moduleServicesGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
