export default {
  pushBonus({ commit }, payload) {
    commit('PUSH_BONUS', payload)
  },
  resetBonus({ commit }) {
    commit('RESET_BONUS')
  },
  addBonus({ commit }, payload) {
    const bonus = {
      bonusName: payload.bonusData.bonusName,
      bonusPrice: payload.bonusData.bonusPrice,
      bonusLimit: payload.bonusData.bonusLimit,
      expiration: payload.bonusData.expiration,
      productId: payload.bonusData.productId,
    }
    Axios.post('/addBonus', { bonus })
      .then(function (response) {
        if (response.data.success) {
          const newBonus = {
            id: response.data.bonusData._id,
            bonusName: payload.bonusData.bonusName,
            bonusPrice: payload.bonusData.bonusPrice,
            bonusLimit: payload.bonusData.bonusLimit,
            expiration: response.data.bonusData.expiration,
            productId: payload.bonusData.productId,
          }
          commit('ADD_BONUS', newBonus)
          payload.notify({
            title: 'Bonus Created',
            text: 'Bonus created in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delBonus({ commit }, payload) {
    Axios.post('/delBonus', { bonus: payload.bonusData })
      .then(function (response) {
        if (response.data.success) {
          commit('DEL_BONUS', payload.bonusData)
          payload.notify({
            title: 'Bonus Deleted',
            text: 'Bonus deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  sellBonus: async (context, payload) => {
    Axios.post('/sellBonus', { payload })
      .then(function (response) {
        if (response.data.success) {
          // context.commit('SELL_BONUS', payload)
          context.dispatch('customers/sellBonus', { payload, response: response.data.data }, { root: true })
          payload.notify({
            title: 'Bonus updated',
            text: 'Bonus updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  putBonus: async (context, payload) => {
    const bonus = {
      id: payload.newData.id,
      bonusName: payload.newData.bonusName,
      bonusPrice: payload.newData.bonusPrice,
      bonusLimit: payload.newData.bonusLimit,
      expiration: payload.newData.expiration,
      productId: payload.newData.productId,
    }
    Axios.post('/putBonus', { bonus })
      .then(function (response) {
        if (response.data.success) {
          context.commit('PUT_BONUS', bonus)
          payload.notify({
            title: 'Bonus updated',
            text: 'Bonus updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  delSelectedActivities(conext, payload) {
    Axios.post('/delMultiActivity', { activity: payload.activityData })
      .then(function (response) {
        if (response.data.success) {
          conext.commit('DEL_SELECTED_ACTIVITIES', payload)
          payload.notify({
            title: 'Activities Deleted',
            text: 'Multi Activities deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {
      })
  },
}
