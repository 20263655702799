export default {
  PUSH_STATES(state, payload) {
    state.state_list = payload
  },
  RESET_STATES(state) {
    state.states = []
  },
  ADD_STATES(state, payload) {
    state.states.push(payload)
    // LocalForageFunctions.setItem('states', state.states)
  },
  DEL_STATES(state, payload) {
    const index = state.states.findIndex(x => x.id === payload.id)
    state.states.splice(index, 1)
    // LocalForageFunctions.setItem('states', state.states)
  },
  PUT_STATES(state, payload) {
    const index = state.states.findIndex(x => x.id === payload.stateData.id)
    state.states.splice(index, 1)
    state.states.push(payload.newData)
    // LocalForageFunctions.setItem('states', state.states)
  },
  DEL_SELECTED_STATES(state, payload) {
    payload.stateData.forEach(element => {
      const index = state.states.findIndex(x => x.id === element.id)
      state.states.splice(index, 1)
    })
    // LocalForageFunctions.setItem('states', state.states)
  },
}
