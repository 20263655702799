import state from './moduleBonusState'
import mutations from './moduleBonusMutations'
import actions from './moduleBonusActions'
import getters from './moduleBonusGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
