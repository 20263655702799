export default {
  pushSchedules: async ({ commit }, payload) => {
    commit('PUSH_SCHEDULES', payload)
  },
  resetSchedules: async ({ commit }) => {
    commit('RESET_SCHEDULES')
  },
  addSchedules: async ({ commit, dispatch }, payload) => {
    payload.event.activeBonus = payload.activeBonus
    Axios.post('/addSchedule', { schedule: payload.event })
      .then(function (response) {
        if (response.data.success) {
          response.data.scheduleData.id = response.data.scheduleData._id
          if (payload.activeBonus) dispatch('customers/updateCustomerBonus', payload.activeBonus, { root: true })
          commit('ADD_SCHEDULES', response.data.scheduleData)
          payload.notify({
            title: 'Event Created',
            text: 'Event created in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  updateSchedules: async ({ commit }, payload) => {
    Axios.post('/putSchedule', { schedule: payload.eventData, type: 'normal' })
      .then(function (response) {
        if (response.data.success) {
          commit('PUT_SCHEDULES', payload)
          payload.notify({
            title: 'Account updated',
            text: 'Schedule updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  deleteSchedules: async ({ commit }, payload) => {
    Axios.post('/delSchedule', { schedule: payload.eventData })
      .then(function (response) {
        if (response.data.success) {
          commit('DEL_SCHEDULES', payload.eventData)
          payload.notify({
            title: 'Schedule Deleted',
            text: 'Schedule deleted in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  updateDragSchedules: async ({ commit }, payload) => {
    Axios.post('/putSchedule', { schedule: payload.eventData, type: 'drag' })
      .then(function (response) {
        if (response.data.success) {
          commit('PUT_DRAG_SCHEDULES', payload)
          payload.notify({
            title: 'Account updated',
            text: 'Schedule updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
  updateRevision: ({ commit }, payload) => {
    commit('PUT_REVISION', payload)
  },
  updateBgColor: async ({ commit }, payload) => {
    Axios.post('/putScheduleColors', { schedule: payload, type: payload.dataType })
      .then(function (response) {
        if (response.data.success) {
          commit('PUT_BGCOLOR_SCHEDULES', payload)
          payload.notify({
            title: 'Account updated',
            text: 'Schedule updated in API',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })
        } else if (response.data.code === 'noAuth') {
          this.$router.push('/pages/login')
          payload.notify({
            title: 'Error',
            text: 'Session timeout.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        } else {
          payload.notify({
            title: 'Error',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(error => {

      })
  },
}
