import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  async getAllBusinessData({ commit, dispatch }) {
    try {
      const getAllData = await this._vm.$http.get('/getAllData')
      if (getAllData.data.success) {
        commit('PUSH_BUSINESS_DATA', {
          businessName: getAllData.data.data.businessData.businessName,
          photoUrl: getAllData.data.data.businessData.resources.find(x => x._id === getAllData.data.data.userInfo._id).photoUrl,
          name: getAllData.data.data.businessData.resources.find(x => x._id === getAllData.data.data.userInfo._id).resourceName,
          uid: getAllData.data.data.businessData._id,
          ownUid: getAllData.data.data.userInfo._id,
          isOwner: getAllData.data.data.businessData.resources.find(x => x._id === getAllData.data.data.userInfo._id).isOwner,
          firstLogin: !!getAllData.data.data.businessData.resources.find(x => x._id === getAllData.data.data.userInfo._id).firstLogin,
        })
        await dispatch('addLoadingStatus', { name: 'statistics', value: true })
        await dispatch('addLoadingStatus', { name: 'schedules', value: true })
        await dispatch('resources/pushResources', getAllData.data.data.businessData.resources, { root: true })
        await dispatch('schedules/pushSchedules', getAllData.data.data.businessData.schedules, { root: true })
        await dispatch('customers/pushCustomers', getAllData.data.data.businessData.customers, { root: true })
        await dispatch('services/pushServices', getAllData.data.data.businessData.services, { root: true })
        await dispatch('activities/pushActivities', getAllData.data.data.businessData.activities, { root: true })
        await dispatch('bonus/pushBonus', getAllData.data.data.businessData.bonus, { root: true })
        await dispatch('states/pushStates', getAllData.data.data.businessData.states, { root: true })
        await dispatch('audit/pushAudit', getAllData.data.data.businessData.audit, { root: true })
        await dispatch('addLoadingStatus', { name: 'statistics', value: false })
        await dispatch('addLoadingStatus', { name: 'schedules', value: false })
        // dispatch('audit/pushUnreadNotifications', {}, { root: true })
      } else {
        throw new Error(getAllData.data.message)
      }
    } catch (err) {
      this._vm.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Uops!',
          icon: 'AlertCircleIcon',
          text: `Parece que ha ocurrido un error: ${err}`,
          variant: 'danger',
        },
      })
    }
  },
  async addLoadingStatus({ commit }, payload) {
    commit('PUT_LOADING', payload)
  },
  resetBusinessData({ commit, dispatch }) {
    dispatch('audit/resetAudit', {}, { root: true })
    dispatch('schedules/resetSchedules', {}, { root: true })
    dispatch('customers/resetCustomers', {}, { root: true })
    dispatch('services/resetServices', {}, { root: true })
    dispatch('states/resetStates', {}, { root: true })
    //dispatch('colorExceptions/resetColorExceptions', {}, { root: true })
    //dispatch('colorStateExceptions/resetStateColorExceptions', {}, { root: true })
    //dispatch('userOptions/resetUserOptions', {}, { root: true })
    //dispatch('employees/resetEmployees', {}, { root: true })
    commit('RESET_BUSINESS_DATA')
  },
  deleteBusinessAcount({ commit, dispatch }, payload) {
    payload.loading()
    return new Promise((resolve, reject) => {
      Axios.post('/delResource', { resource: { id: payload.resource.id } })
        .then(response => {
          if (response.data.success) {
            dispatch('businessData/resetBusinessData', {}, { root: true })
          }
          resolve(response)
          payload.loading.close()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  callGenerateICS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Axios.post('/generateIcs')
        .then(response => {
          if (response.data.success) {
          }
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  serveICS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Axios.get('/serveIcs')
        .then(response => {
          if (response.data.success) {
          }
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeFirstLoginStatus({ commit, dispatch }, payload) {
    Axios.post('/resetPassword', { firstLogin: payload.password })
      .then(response => {
        if (response.data.success) {
          commit('CHANGE_FIRSTLOGIN', false)
          router.replace('/')
          payload.loading.close()
          payload.notify({
            time: 2500,
            // title: '',
            text: 'Contraseña cambiada',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
        } else {
          payload.loading.close()
          payload.notify({
            time: 2500,
            // title: '',
            text: 'Ha ocurrido un error, vuelva a intentarlo',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      })
      .catch(err => {
        payload.loading.close()
        payload.notify({
          time: 2500,
          // title: '',
          text: 'Ha ocurrido un error, vuelva a intentarlo',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
}
