export default {
  AUDIT: state => state.audit,
  UNREAD_AUDIT: state => state.audit.length,
  UNREAD_NOTIFY_FLAG: state => state.notificationLabel,
  TEST_UNNOTIFICATIONS: state => state.unreadNotifications,
  TEST_NOTIFICATIONS: state => state.notifications,
  /* UNREAD_NOTIFICATIONS: (state, getters, rootState, rootGetters) => {
    let notifications = []
    let notificationLabel = ''
    let uid = rootGetters['businessData/UID']
    let ownUid = rootGetters['businessData/OWNUID']
    let employees = rootGetters['employees/EMPLOYEES']
    let currentUser = employees.filter(x => x.id === ownUid)
    let unreadFlag = false
    let unreadNotifications = currentUser[0].unreadNotifications.filter(x => x.read === false)
    let allNotifications = deepClone(currentUser[0].unreadNotifications)
    for(let i=0; i<unreadNotifications.length;i++){
      let notification = unreadNotifications[i]
        switch(notification.category){
          case 'customer':
            if(currentUser[0].notifications.showCustomersN && notification.resourceId != uid && uid === ownUid){
              unreadFlag = true
              notifications.push(notification)
            }
          break;
          case 'resource':
            if(currentUser[0].notifications.showResourcesN && notification.resourceId != uid && uid === ownUid){
              unreadFlag = true
              notifications.push(notification)
            }
          break;
          case 'service':
            if(currentUser[0].notifications.showServicesN && notification.resourceId != uid && uid === ownUid){
              unreadFlag = true
              notifications.push(notification)
            }
          break;
          case 'state':
            if(currentUser[0].notifications.showStatesN && notification.resourceId != uid && uid === ownUid){
              unreadFlag = true
              notifications.push(notification)
            }
          break;
          case 'schedule':
            if(currentUser[0].notifications.showSchedulesN && notification.resourceId != ownUid && notification.assignedTo === ownUid){
              unreadFlag = true
              notifications.push(notification)
            }
          break;
        }

        if(unreadFlag){
          notificationLabel = true
        }else{
          notificationLabel = false
        }
    }

    return {notificationLabel: notificationLabel, unreadNotifications: notifications.sort((a,b) => (a.changeData.changeDate > b.changeData.changeDate) ? -1 : ((b.changeData.changeDate > a.changeData.changeDate) ? 1 : 0)), notifications: allNotifications.sort((a,b) => (a.changeData.changeDate > b.changeData.changeDate) ? -1 : ((b.changeData.changeDate > a.changeData.changeDate) ? 1 : 0))};
  }  */
}
