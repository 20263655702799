import state from './moduleAllDataState'
import mutations from './moduleAllDataMutations'
import actions from './moduleAllDataActions'
import getters from './moduleAllDataGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
