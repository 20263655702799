import Vue from 'vue'

// axios
import axios from 'axios'

// const baseURL = 'https://api.schedit.app/api';

const baseURL = 'https://api.schedit.app/api'
//const baseURL = 'http://localhost:8000/api'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL,
})

Vue.prototype.$http = axiosIns

export default axiosIns
