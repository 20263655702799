import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { auth } from '@/auth/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'main-dashboard' } },
    ...apps,
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  onAuthStateChanged(auth, user => {
    if (user) {
      if (to.meta.redirectIfLoggedIn) {
        next('/')
      }
    } else if (to.path !== '/login') {
      if (
        to.path === '/register'
        || to.path === '/forgot-password'
        || to.path === '/error-404'
        || to.path === '/pages/miscellaneous/coming-soon'
      ) {
        next()
      } else next('/login')
    }
    next()
  })

  // return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
