export default {
  PUSH_BUSINESS_DATA(state, payload) {
    state.businessName = payload.businessName
    state.photoUrl = payload.photoUrl
    state.uid = payload.uid
    state.ownUid = payload.ownUid
    state.isOwner = payload.isOwner
    state.dataStatus = true
    state.name = payload.name
    state.firstLogin = payload.firstLogin
  },
  PUT_LOADING(state, payload) {
    state.loading[`${payload.name}`] = payload.value
  },
  RESET_BUSINESS_DATA(state) {
    state.businessName = ''
    state.uid = ''
    state.ownUid = ''
    state.isOwner = ''
    state.photoUrl = ''
  },
  PUT_PHOTOURL(state, payload) {
    state.photoUrl = payload
  },
  PUT_APPSTATE(state, payload) {
    state.appReady = payload
  },
  CHANGE_FIRSTLOGIN(state, payload) {
    state.firstLogin = payload
  },
}
