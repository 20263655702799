export default {
  businessName: '',
  uid: '',
  isOwner: '',
  ownUid: '',
  photoUrl: '',
  dataStatus: false,
  name: '',
  firstLogin: '',
  loading: {
    statistics: true,
    schedules: true,
  },
}
