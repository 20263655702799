import state from './moduleCustomersState'
import mutations from './moduleCustomersMutations'
import actions from './moduleCustomersActions'
import getters from './moduleCustomersGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
