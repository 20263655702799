export default {
  PUSH_CUSTOMERS(state, payload) {
    state.customer_list = payload
  },
  SET_CUSTOMER_SEARCH_QUERY(state, query) {
    state.customerSearchQuery = query
  },
  ADD_CUSTOMER_BONUS(state, payload) {
    const index = state.customers.findIndex(x => x.id === payload.payload.bonusInfo.customer.id)
    state.customers[index].bonus.push(payload.response)
    // state.customers.push(payload.newData)
  },
  PUT_CUSTOMER_BONUS(state, payload) {
    const index = state.customers.findIndex(x => x.id === payload.customerId)
    const indexBonus = state.customers[index].bonus.findIndex(x => x._id === payload.bonusId)
    payload.sessionCount += 1
    if (payload.sessionCount < payload.bonusLimit) {
      state.customers[index].bonus[indexBonus].sessionCount = payload.sessionCount + 1
    } else if (payload.sessionCount >= payload.bonusLimit) {
      state.customers[index].bonus[indexBonus].sessionCount = payload.sessionCount + 1
      state.customers[index].bonus[indexBonus].bonusUsed = true
      state.customers[index].bonus[indexBonus].bonusExpired = true
    }

    // state.customers.push(payload.newData)
  },
  RESET_CUSTOMERS(state) {
    state.customers = []
    // LocalForageFunctions.setItem('customers', state.customers)
  },
  ADD_CUSTOMERS(state, payload) {
    state.customer_list.push(payload)
    // LocalForageFunctions.setItem('customers', state.customers)
  },
  BULK_CUSTOMERS(state, payload) {
    for (let i = 0; i < payload.length; i++) {
      state.customers.push(payload[i])
    }
    // LocalForageFunctions.setItem('customers', state.customers)
  },
  DEL_CUSTOMERS(state, payload) {
    const index = state.customer_list.findIndex(x => x._id === payload)
    state.customer_list.splice(index, 1)
    // LocalForageFunctions.setItem('customers', state.customers)
  },
  PUT_CUSTOMERS(state, payload) {
    const index = state.customer_list.findIndex(x => x._id === payload._id)
    state.customer_list.splice(index, 1)
    state.customer_list.push(payload)
  },
  DEL_SELECTED_CUSTOMERS(state, payload) {
    payload.customerData.forEach(element => {
      const index = state.customers.findIndex(x => x.id == element.id)
      state.customers.splice(index, 1)
    })
    // LocalForageFunctions.setItem('customers', state.customers)
  },
}
