/*= ========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

export default {
  SCHEDULES: state => state.schedules,
  getHistory: state => customer => {
    const filteredSchedules = state.schedule_list.filter(schedule => schedule.customer != undefined && schedule.customer === customer)
    return filteredSchedules
  },
}
