import state from './moduleResourcesState'
import mutations from './moduleResourcesMutations'
import actions from './moduleResourcesActions'
import getters from './moduleResourcesGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
